import { StoryblokComponent, storyblokEditable } from "gatsby-source-storyblok"
import React, { useLayoutEffect, useState } from "react"

import Modal from "../../../V2/Sections/Modal"

import { getUrlFromStoryblokLink, linkIsNotNull } from "@utils/storyblok"

const StoryblokModalSection = ({
  blok,
}: Storyblok.BlokProps<Storyblok.ModalSection>) => {
  const [isOpen, setIsOpen] = useState(false)

  useLayoutEffect(() => {
    // TODO: This timeout was added back as a (hopefully) temporary fix for the modals
    // not showing up. It looks like the issue was due to eventListeners not being
    // attached to the links that should open the modals. This problem was likely
    // addressed by making the useEffect a useLayoutEffect (see https://gitlab.com/yoco/yoco-websites/yoco-com/-/merge_requests/1395/diffs?commit_id=6ddd9b6a10519bfafcbf3f889d3ed0b507620a50).
    // For now, it is a somewhat janky fix.
    const timeout = setTimeout(() => {
      const links = document.querySelectorAll(`a[href*="#${blok.id}"]`)
      const onLinkClick = (e: any) => {
        e.preventDefault()
        setIsOpen(true)
      }

      if (links) {
        links.forEach((link) => link.addEventListener("click", onLinkClick))
      }

      const urlParams = new URLSearchParams(window.location.search)
      const modal_open_query_param = urlParams.get(
        process.env.GATSBY_MODAL_OPEN_QUERY_PARAM || ""
      )

      if (modal_open_query_param === blok.id) {
        setIsOpen(true)
      }

      // Cleanup function for useLayoutEffect
      return () => {
        if (links) {
          links.forEach((link) =>
            link.removeEventListener("click", onLinkClick)
          )
        }
        clearTimeout(timeout)
      }
    }, 1000)
  }, [blok.id])

  const footer = blok.footerImage?.filename
    ? {
        style: blok.footerStyle,
        textColor: blok.footerTextColor,
        title: blok.footerTitle || "",
        body: blok.footerBody || "",
        imageUrl: blok.footerImage?.filename,
        imageAlt: blok.footerImage?.alt,
        ctaText: blok.footerCtaText,
        ctaUrl: getUrlFromStoryblokLink(blok.footerCtaLink),
        ctaColor: blok.footerCtaColor,
        backgroundColor: blok.footerBackgroundColor,
      }
    : undefined

  // TODO: Visit this component to check for performance related issues after lazy load implementation based on the comment here
  // https://gitlab.com/yoco/yoco-websites/yoco-com/-/merge_requests/1395#note_1792578636

  return (
    <Modal
      backgroundColor={blok.backgroundColor}
      title={blok.title}
      body={blok.body}
      buttonText={blok.ctaText}
      buttonLink={
        blok.ctaLink && linkIsNotNull(blok.ctaLink)
          ? getUrlFromStoryblokLink(blok.ctaLink)
          : ""
      }
      buttonBorderColor={blok.ctaBorderColor}
      isOpen={isOpen}
      footer={footer}
      onClick={() => setIsOpen(false)}
      trackingEvent={blok.trackingEvent}
      trackingEventKey={blok.trackingEventKey}
      trackingEventValue={blok.trackingEventValue}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    >
      {blok.content?.map((blok) => (
        <StoryblokComponent key={blok._uid} blok={blok} />
      ))}
    </Modal>
  )
}

export default StoryblokModalSection
